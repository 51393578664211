<template>
    <div class="card st-framework-card" v-if="pageLoaded">

        <div class="card-header">
            <table style="border-collapse: collapse; width: 100%;">
                <tr>
                    <td style="margin: 0; padding: 0 0 0 1rem; width: 280px; border: solid 1px lightgray;">
                        <font-awesome-icon :icon="icon" size="sm" class="mr-2"/>
                        <SelectAction :action="actions.selectViewAction" :disabled="state.disabled"
                                      v-on:select-view="selectView"/>
                    </td>
                    <td style="text-align: center;">
                        {{ $i18n.tc('translations.' + name, 1) }}
                    </td>
                    <td style="width: 270px;"/>
                </tr>
            </table>
        </div>

        <div class="card-body st-fadein-content" style="padding-top: 0;">

            <!-- STICKY HEADER -->

            <div class="sticky-header">

                <!-- FILTER -->

                <b-input-group size="sm" class="mb-3">
                    <b-input-group-prepend is-text>
                        <font-awesome-icon :icon="['fas', 'search']"/>
                    </b-input-group-prepend>
                    <b-form-input type="search" ref="filter"
                                  :placeholder="$i18n.tc('translations.Enter a number of search terms separated by a space',1)"
                                  v-model="filter"
                                  v-on:input="debounceInput"/>
                    <b-form-datepicker v-model="filterFrom" style="max-width: 180px;"
                                       :placeholder="$i18n.t('translations.From')"
                                       :date-format-options="{year: 'numeric', month: '2-digit', day: '2-digit'}"
                                       start-weekday="1"
                                       today-button reset-button close-button boundary="window" :locale="userLanguage"
                                       v-on:input="filterRows"/>
                    <b-form-datepicker v-model="filterTo" style="max-width: 180px;"
                                       :placeholder="$i18n.t('translations.To')"
                                       :date-format-options="{year: 'numeric', month: '2-digit', day: '2-digit'}"
                                       start-weekday="1"
                                       today-button reset-button close-button boundary="window" :locale="userLanguage"
                                       v-on:input="filterRows"/>
                    <b-button squared variant="success" size="sm"
                              v-on:click="fetchData">
                        {{ $i18n.t('translations.Refresh') }}
                    </b-button>
                    <b-input-group-append>
                        <b-dropdown :text="$i18n.tc('translations.Column',2)" size="sm" right v-on:hide="saveUserSettings">
                            <b-dropdown-form style="width: 320px;">
                                <template v-for="field in fields.tableFields">
                                    <b-input-group :key="'column' + field.id">
                                        <b-form-checkbox size="sm" name="checkbox" v-model="field.showintable"
                                                         v-on:change="setTopScrollBarWidth">
                                            {{ field.label }}
                                        </b-form-checkbox>
                                    </b-input-group>
                                </template>
                            </b-dropdown-form>
                        </b-dropdown>
                        <template v-for="(action,index) in actions.manageActions">
                            <DropDownAction :key="action.name + '-' + index" :action="action"
                                            v-on:download="download($event)"
                                            v-if="action.placement === 'FILTERRIGHT' && action.type === 'DROPDOWN'"/>
                        </template>
                    </b-input-group-append>
                </b-input-group>

                <div class="row" style="padding: 0; margin: 0;">

                    <!-- FIXED TABLE AREA -->

                    <div class="col fixed-table-area" style="margin: 16px 0 0 0;">
                        <div style="overflow: hidden;">
                            <table class="table work-plan-table">
                                <thead>
                                <tr class="fixed-table-area-row">
                                    <th>{{ $i18n.tc('translations.Phase', 1) }}</th>
                                    <th>{{ $i18n.tc('translations.Reference', 1) }}</th>
                                </tr>
                                </thead>
                            </table>
                        </div>
                    </div>

                    <!-- SCROLLABLE TABLE AREA -->

                    <div ref="scrollable-table-area" class="col scrollable-table-area">
                        <div ref="top-scrollbar-wrapper" class="top-scrollbar-wrapper"
                             v-on:scroll="scrollTopScrollBar">
                            <div class="top-scrollbar" :style="top_scrollbar_style"/>
                        </div>
                        <div ref="scrollable-header-wrapper" style="overflow: hidden;">
                            <table ref="scrollarea" class="table work-plan-table">
                                <thead>
                                <tr>
                                    <template v-for="(field,fieldIndex) in fields.tableFields">
                                        <template v-if="field.showintable">
                                            <th :key="'th-'+fieldIndex" :style="field.thstyle" :title="field.label">
                                                {{ field.label }}
                                            </th>
                                        </template>
                                    </template>
                                </tr>
                                </thead>
                            </table>
                        </div>
                    </div>

                </div>
            </div>

            <!-- TABLE -->

            <div class="row m-0 p-0">

                <!-- FIXED TABLE AREA -->

                <div class="col fixed-table-area">
                    <table class="table table-striped work-plan-table">
                        <tbody>
                        <template v-for="(row,rowIndex) in data">
                            <template v-if="rowState[row.id].visible">
                                <tr class="fixed-table-area-row" :key="'tr-'+row.id+'-'+rowIndex" :class="{'selected-row': (row.id === selectedRow)}"
                                    v-on:click="selectTableRow(row)"
                                    v-on:dblclick="gotoWorkPlan(row)">
                                    <td :class="{'st-warning-backgroud': (rowState[row.id].diy_kit && !rowState[row.id].wait_for_building_permit), 'wait-for-building-permit': rowState[row.id].wait_for_building_permit}">
                                        <table class="table-borderless" style="table-layout: fixed; width: 100%;">
                                            <tr style="background: none;" v-if="row.show_work_plan_link">
                                                <td style="text-align: center; margin: 0; padding: 0 5px 5px 5px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                                    <font-awesome-icon :icon="['fas','bell']" class="mr-2" style="color: #838fa3;" v-if="row.reminders.length > 0"/>
                                                    <LinkAction :action="row.work_plan_link_action" v-if="row.work_plan_link_action"/>
                                                    <template v-else>{{ row.label }}</template>
                                                </td>
                                            </tr>
                                            <tr style="background: none;">
                                                <td style="text-align: center; margin: 0; padding: 0 5px 5px 5px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                                    <LinkAction :action="row.order_link_action" v-if="row.order_link_action"/>
                                                    <template v-else>{{ row.order_label }}</template>
                                                </td>
                                            </tr>
                                            <tr style="background: none;">
                                                <td style="text-align: center; margin: 0; padding: 0 5px 0 5px; white-space: nowrap; text-overflow: ellipsis; overflow: hidden;">
                                                    <LinkAction :action="row.project_link_action" v-if="row.project_link_action"/>
                                                    <template v-else>{{ row.project_label }}</template>
                                                </td>
                                            </tr>
                                        </table>
                                    </td>
                                    <td>{{ row.project_reference }}</td>
                                </tr>
                            </template>
                        </template>
                        </tbody>
                    </table>
                </div>

                <!-- SCROLLABLE TABLE AREA -->

                <div ref="scrollable-table-area" class="col scrollable-table-area">
                    <div ref="scrollable-area-wrapper" class="scrollable-area-wrapper"
                         v-on:scroll="scrollBottomScrollBar">
                        <div ref="scrollable-area">
                            <table class="table table-striped work-plan-table">
                                <tbody>
                                <template v-for="(row,rowIndex) in data">
                                    <template v-if="rowState[row.id].visible">
                                        <tr :key="'tr-'+row.id+'-'+rowIndex" :class="{'selected-row': (row.id === selectedRow)}"
                                            v-on:click="selectTableRow(row)"
                                            v-on:dblclick="gotoWorkPlan(row)">
                                            <template v-for="(field,fieldIndex) in fields.tableFields">
                                                <template v-if="field.showintable">
                                                    <td :key="'td-'+fieldIndex+'-'+rowIndex" :class="field.tdclass" :style="field.tdstyle">
                                                        <Field :field="field" :data="row" :state="state"
                                                               v-on:inline-edit="inlineEdit(row.id, field, $event)"/>
                                                    </td>
                                                </template>
                                            </template>
                                        </tr>
                                    </template>
                                </template>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
import * as _ from "debounce";
import DropDownAction from "@/components/actions/DropDownAction";
import Field from "@/components/fields/Field";
import FileDownload from "@/mixins/FileDownload";
import moment from "moment";
import SelectAction from "@/components/actions/SelectAction.vue";
import LinkAction from "@/components/actions/LinkAction.vue";

export default {
    name: "WorkPlanList",
    components: {
        LinkAction,
        SelectAction,
        DropDownAction,
        Field,
    },
    props: ['name', 'icon', 'api', 'state', 'userLanguage', 'languages', 'permissions'],
    mixins: [FileDownload],
    data() {
        return {
            pageLoaded: false,
            actions: null,
            fields: null,
            data: null,
            filter: '',
            filterFrom: null,
            filterTo: null,
            rowState: {},
            selectedRow: null,
            top_scrollbar_style: '',
        }
    },
    created() {
        this.fetchData();
    },
    methods: {
        debounceInput: _.debounce(function () {
            this.filterRows();
        }, 450),
        download(choice) {
            this.state.loading = true;
            let ids = [];
            this.data.forEach(row => {
                if (this.rowState[row.id].visible) {
                    ids.push(row.id);
                }
            })
            this.$http.post(choice.api, {'ids': ids}, {'responseType': 'blob'}).then((res) => {
                this.fileDownload(choice, res.data);
                this.state.loading = false;
            }).catch((error) => {
                console.log("WorkPlanList:download():error:", error);
            });
        },
        fetchData() {
            this.state.loading = true;
            this.$http.get(this.api + '/manage_list', {}).then((res) => {
                this.actions = res.data.actions;
                this.fields = res.data.fields;
                this.data = res.data.data;
                this.data.forEach(row => {
                    this.rowState = {
                        ...this.rowState,
                        [row.id]: {
                            visible: true,
                            diy_kit: row.diy_kit,
                            wait_for_building_permit: (row.task_building_permit && !row.task_approved),
                        }
                    }
                })
                this.pageLoaded = !!this.data;
                this.state.loading = false;
                this.filterRows();
                this.setTopScrollBarWidth();
            }).catch((error) => {
                this.data = null;
                console.log("WorkPlanList:fetchData():error:", error);
            });
        },
        filterOnDate(row) {
            if (!this.filterFrom && !this.filterTo) {
                return true;
            }

            let date = row.delivery_date ? row.delivery_date : row.expected_delivery_date;
            if (!date) {
                return false;
            }

            return (
                (!this.filterFrom || (moment(date) >= moment(this.filterFrom))) &&
                (!this.filterTo || (moment(date) <= moment(this.filterTo)))
            );
        },
        filterOnText(row) {
            if (!this.filter) {
                return true;
            }
            let concatenatedData = row.label + ' ' + row.order_label + ' ' + row.project_label + ' ' + row.project_reference;
            this.fields.tableFields.forEach(field => {
                if (field.showintable && row[field.name] !== null) {
                    switch (field.type) {
                        case 'DATE':
                            concatenatedData += ' ' + moment(String(row[field.name])).format('DD/MM/YYYY');
                            break;
                        case 'LINK':
                            concatenatedData += ' ' + row[field.name].label;
                            break;
                        default:
                            concatenatedData += ' ' + row[field.name];
                            break;
                    }
                }
            });
            concatenatedData = concatenatedData.toLowerCase();
            let words = this.filter.toLowerCase().split(" ");
            return words.every(word => (String(concatenatedData).indexOf(word) !== -1));
        },
        filterOnView(row) {
            return (
                this.actions.selectViewAction.selected === 'FULL' ||
                (this.actions.selectViewAction.selected === 'WITHOUTBUILDINGPERMIT' && !(row.task_building_permit && !row.task_approved)) ||
                (this.actions.selectViewAction.selected === 'WAITFORBUILDINGPERMIT' && row.task_building_permit && !row.task_approved) ||
                (this.actions.selectViewAction.selected === 'WAITFORAPPROVAL' && row.task_sent_to_customer && !row.task_approved) ||
                (this.actions.selectViewAction.selected === 'PRODUCTION' && row.task_checked && !row.task_assembly_done)
            );
        },
        filterRows() {
            this.data.forEach(row => {
                this.rowState[row.id].visible = (this.filterOnView(row) && this.filterOnDate(row) && this.filterOnText(row));
            });
            this.$nextTick(() => {
                this.$refs.filter.focus();
            });
        },
        gotoWorkPlan(row) {
            this.$router.push({name: 'work_plans', params: {id: row.order_id}});
        },
        inlineEdit(phaseId, field, data) {
            if (field.name === "expected_delivery_date" || field.name === "delivery_date") {
                this.fetchData();
            } else {
                let idx = this.data.findIndex(row => row.id === phaseId);
                this.data[idx] = data.data;
                this.rowState[phaseId].diy_kit = data.data.diy_kit;
                this.rowState[phaseId].wait_for_building_permit = (data.data.task_building_permit && !data.data.task_approved);
                this.filterRows();
                this.state.loading = false;
            }
        },
        saveUserSettings() {
            let columns = {};
            this.fields.tableFields.forEach(field => {
                if (!field.showintable) {
                    columns[field.name] = false;
                }
            });
            this.state.loading = true;
            this.$http.post(this.api + '/update_settings', {
                'module': 'WORKPLANLIST',
                'settings': {
                    'SELECTVIEW': this.actions.selectViewAction.selected,
                    'COLUMNS': columns,
                }
            }, {}).then(() => {
                this.state.loading = false;
            }).catch((error) => {
                console.log("WorkPlanList:saveUserSettings():error:", error);
            });
        },
        scrollBottomScrollBar() {
            let topScrollBar = this.$refs['top-scrollbar-wrapper'];
            let bottomScrollBar = this.$refs['scrollable-area-wrapper'];
            topScrollBar.scrollLeft = bottomScrollBar.scrollLeft;
        },
        scrollTopScrollBar() {
            let topScrollBar = this.$refs['top-scrollbar-wrapper'];
            let bottomScrollBar = this.$refs['scrollable-area-wrapper'];
            let headerScrollBar = this.$refs['scrollable-header-wrapper'];
            bottomScrollBar.scrollLeft = topScrollBar.scrollLeft;
            headerScrollBar.scrollLeft = topScrollBar.scrollLeft;
        },
        selectTableRow(row) {
            this.selectedRow = row.id;
        },
        selectView() {
            this.saveUserSettings();
            this.filterRows();
        },
        setTopScrollBarWidth() {
            this.$nextTick(function () {
                let scrollArea = this.$refs['scrollarea'];
                if (scrollArea) {
                    this.top_scrollbar_style = 'width: ' + scrollArea.clientWidth + 'px;';
                }
            });
        },
    }
}
</script>

<style scoped>
.fixed-table-area {
    width: 600px;
    min-width: 600px;
    max-width: 600px;
    padding: 0;
    z-index: 400;
}

.fixed-table-area-row > th:first-child,
.fixed-table-area-row > td:first-child {
    width: 390px;
    margin: 0;
    padding: 0;
}

.fixed-table-area-row > th:last-child,
.fixed-table-area-row > td:last-child {
    width: 210px;
}

.scrollable-area-wrapper {
    overflow-y: visible;
    overflow-x: scroll;
}

.top-scrollbar-wrapper {
    overflow-y: hidden;
    overflow-x: scroll;
    height: 16px;
}

.scrollable-table-area {
    width: calc(100% - 600px);
    margin: 0;
    padding: 0;
}

.top-scrollbar {
    height: 16px;
}

.sticky-header {
    position: sticky;
    top: 38px;
    background: white;
    padding-top: 1rem;
    z-index: 500;
}

.work-plan-table {
    table-layout: fixed;
    border-collapse: collapse;
    margin: 0;
    border: 1px solid #dee2e6;
}

.work-plan-table > thead > tr > th {
    height: 70px;
    text-align: center;
    margin: 0;
    border: 1px solid #dee2e6;
}

.work-plan-table > tbody > tr > td {
    height: 100px;
    border: 1px solid #dee2e6;
}

.work-plan-table > tbody > tr.selected-row {
    outline: 1px solid darkslategray;
    background: lightgreen;
}

.work-plan-table > tbody > tr > td >>> .ck-content {
    height: 98px;
    min-height: 98px;
    max-height: 98px;
}

.wait-for-building-permit {
    background-color: #D4EFDF;
}

a:link,
a:visited {
    color: black;
    text-decoration: none;
}

a:hover,
a:active {
    color: black;
    text-decoration: underline;
}
</style>
